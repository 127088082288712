export default {
    namespaced: true,

    state: () => ({
        attendance_records        : [],
        attendance_summary_reports: [],
        employee_statement        : [],
        employee_attendances      : [],
        attendance_list           : [],
        absent_list               : [],
        missing_list              : [],
    }),

    getters: {
        attendance_records(state) {
            return state.attendance_records;
        },
        attendance_summary_reports(state) {
            return state.attendance_summary_reports;
        },
        employee_statement(state) {
            return state.employee_statement;
        },
        employee_attendances(state) {
            return state.employee_attendances;
        },
        attendance_list(state) {
            return state.attendance_list;
        },
        missing_list(state) {
            return state.missing_list;
        },
        absent_list(state) {
            return state.absent_list;
        },
    },

    mutations: {
        addAttendanceRecords(state, records) {
            state.attendance_records = records;
        }, 
        addAttendanceSummaryReports(state, records) {
            state.attendance_summary_reports = records;
        },
        addEmployeeAttendances(state, records) {
            state.employee_attendances = records;
        },
        addEmployeeStatement(state, statement) {
            state.employee_statement = statement;
        },
        attendance_list(state, attendance_list){
            state.attendance_list = attendance_list;
        },
        missing_list(state, missing_list){
            state.missing_list = missing_list;
        },
        absent_list(state, absent_list){
            state.absent_list = absent_list;
        },
    },

    actions: {
        async saveAttendance(ctx, attendanceData) {
            let isSuccess = false;

            await axios.post(`/add-employee-attendance`, attendanceData)
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },
        async attendanceProcess(ctx){
            let isSuccess = false;

            await axios.get(`/attendance-process`)
            .then(res => {
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                isSuccess = false;
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },

        async getAttendanceRecords(ctx, { apiParams = {}, options = {} } = {}) {
            let records = await axios.post(`/get_attendance_records`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData) {
                return records;
            }

            ctx.commit('addAttendanceRecords', records.records);
            ctx.commit('addEmployeeStatement', records.employee_statement);

        },
        async getAttendanceLogs(ctx, apiParams = {}) {
            let records = await axios.post(`/get_attendance_logs`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            return records;

        },
        async getAttendanceSummaryReports(ctx, { apiParams = {}, options = {} } = {}) {
            let records = await axios.post(`/get_attendance_summary_reports`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData) {
                return records;
            }

            ctx.commit('addAttendanceSummaryReports', records);

        },
        async getEmployeeAttendances(ctx, { apiParams = {}, options = {} } = {}) {
            let records = await axios.post(`/get_employee_attendances`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData) {
                return records;
            }

            ctx.commit('addEmployeeAttendances', records);
        },
        async getAttendanceList(ctx, { apiParams = {}, options = {} } = {}) {
            let records = await axios.post(`/get_attendance_list`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData) {
                return records;
            }

            ctx.commit('attendance_list', records);
        },
        async getAbsentList(ctx, { apiParams = {}, options = {} } = {}) {
            let records = await axios.post(`/get_absent_list`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData) {
                return records;
            }

            ctx.commit('absent_list', records);
        },
        async getMissingList(ctx, { apiParams = {}, options = {} } = {}) {
            let records = await axios.post(`/get_missing_list`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData) {
                return records;
            }

            ctx.commit('missing_list', records);
        },
    }
}

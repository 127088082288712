export default {
    namespaced: true,

    state: () => {
        return {
            loadingLines: false,
            lines: [],
        }
    },

    getters: {
        loadingLines(state) {
            return state.loadingLines;
        },

        lines(state) {
            return state.lines;
        },
    },

    mutations: {
        loadingLines(state, isLoading) {
            state.loadingLines = isLoading;
        },

        addLines(state, lines) {
            state.lines = lines;
        },
    },

    actions: {
        async getLines(ctx, { apiParams = {}, options = {} } = {}) {
            ctx.commit('loadingLines', true);

            let lines = await axios.post(`/get_lines`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
            
            ctx.commit('loadingLines', false);

            if(options.returnData) {
                return lines;
            }
            
            ctx.commit('addLines', lines);
        },

        async saveLine(ctx, data) {
            
            let returnData = {isSuccess: false};

            await axios.post(data.url, data.line)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getLines');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });

            return returnData;
        },

        async deleteLine(ctx, id) {
            await axios.post(`/delete-line`, {id})
            .then(res => {
                ctx.dispatch('getLines');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
